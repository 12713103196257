import { Injectable } from '@angular/core';
import { IMG_SIZE } from '../constants/images-size';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ImagesService {

  getUrl(url: string, size: any, extension: string = 'jpeg') {
    let newImgUrl = '';
    if (url === '') {
      newImgUrl = 'https://s3.us-east-2.amazonaws.com/octopus.product.assets.dev/no_photo.gif';
    } else {
      const size2 = IMG_SIZE[size.name].name || 'release';
      const parentFolderName = url.split('/').slice(-2)[0]; // parent directory name
      const pathWhithoutExt = url.replace(/\.[^/.]+$/, ''); // remove extension
      newImgUrl = pathWhithoutExt.replace(parentFolderName, size2) + '.' + extension;
      newImgUrl = newImgUrl.replace(environment.S3_BUCKET, environment.S3_BUCKET_CLOUD_FRONT);
      newImgUrl = newImgUrl.replace(environment.S3_BUCKET_WEB, environment.S3_BUCKET_CLOUD_FRONT);
    }
    return newImgUrl;
  }

}
