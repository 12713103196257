import { Component, Input, OnDestroy, OnInit, Inject, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { IMG_SIZE } from 'src/app/constants/images-size';
import { FileObject, ContainerEvents, FileObjectStatus } from 'src/app/services/types';
import { ImagesService  } from 'src/app/services/images.service';
import { S3UploadService } from 'src/app/services/s3-upload.service';

@Component({
  moduleId: module.id,
  selector: 'app-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnDestroy, OnInit {
  @Input() fileObject: FileObject;
  @Input() events: Observable<any>;
  @Output() imageUploaded = new EventEmitter<any>();
  @Output() updateMain = new EventEmitter<any>();
  @Output() deleteImageEmiter = new EventEmitter<any>();

  FileObjectStatus = FileObjectStatus;
  progress = 0;
  speed = 0;
  uploadError: string;
  containerEventSubscription: Subscription;
  eventsSubscription: any;
  uploadHandle: any;
  imageUrl: string;
  isMain = false;
  originalImgUrl = ''; // TODO

  constructor(private s3UploadService: S3UploadService, private imageService: ImagesService) {

    this.containerEventSubscription = s3UploadService.uploadContrainerEvent$.subscribe(
      containerEvent => this.handleContainerEvent(containerEvent)
    );

  }

  ngOnInit() {
    if (this.fileObject.prefix) {
      this.s3UploadService.setPrefix(this.fileObject.prefix);
    }
    this.upload();
    this.eventsSubscription = this.events.subscribe((data) => {
      if (data.action === 'setImagetoMain') {
        this.isMain = ( data.url === this.fileObject.image);
      }
      if (data.action === 'clearMainImage') {
        this.isMain = false;
      }
    });
  }

  private handleContainerEvent(containerEvent: ContainerEvents) {
    switch (containerEvent) {
      case ContainerEvents.Upload:
        return this.fileObject.status === FileObjectStatus.NotStarted && this.upload();
      case ContainerEvents.Cancel:
        return this.fileObject.status === FileObjectStatus.Uploading && this.cancel();
      case ContainerEvents.Delete:
        return this.deleteImage();
      default:
        break;
    }
  }

  upload() {
    this.fileObject.status = FileObjectStatus.Uploading;
    this.uploadError = undefined;
    this.progress = 0;    
    this.uploadHandle = this.s3UploadService.upload(this.fileObject.file, this.handleS3UploadProgress());
  }

  handleS3UploadProgress() {
    return (error: Error, progress: number, speed: number, data: any) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.fileObject.status = FileObjectStatus.Failed;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100 && data != null) {
          const url = this.s3UploadService.getImageUrl(data);
          this.fileObject.image = url;
          this.imageUploaded.emit(url);
          this.fileObject.status = FileObjectStatus.Uploaded;
        }
      }
    };
  }

  cancel() {
    if (this.fileObject.status === FileObjectStatus.Uploading) {
      this.fileObject.status = FileObjectStatus.Canceled;
      this.s3UploadService.cancel(this.uploadHandle);
    }
  }

  deleteImage() {
    this.isMain = false;
    if (this.fileObject.status !== FileObjectStatus.Uploading) {
      this.fileObject.status = FileObjectStatus.Deleted;
      this.s3UploadService.publishFileUploadEvent(this.fileObject);
    }
    this.deleteImageEmiter.emit(this.fileObject.image);
  }

  markAsMain() {
    this.updateMain.emit(this.fileObject.image);
    this.isMain = true;
  }

  getImgUrl() {
    if (this.imageUrl) {
      return this.imageUrl;
    } else {
      this.imageUrl = this.imageService.getUrl(this.fileObject.image, IMG_SIZE.medium, 'jpeg');
    }
    return this.imageUrl;
  }

  ngOnDestroy() {
    this.containerEventSubscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
  }

}
