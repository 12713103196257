// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableLogger: false,
  STATIC_URL: 'http://localhost:8080/',
  API_URL: 'http://localhost:8080/api',
  DATA_MOCK: '/assets/data/',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCWbBajbUrFgKueGo3gYDHz0YjN8zTCtlo',
  AWS_USER: 'serverless-admin',
  AWS_ACCESS_KEY_ID: 'AKIAJB22SLGI2UALDXNQ',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'us-east-2',
  S3_BUCKET_CLOUD_FRONT: 'https://d2m3zdlmvgzju8.cloudfront.net',
  S3_BUCKET_WEB: 'http://octopus.product.assets.dev.s3-website.us-east-2.amazonaws.com',
  S3_BUCKET: 'https://s3.us-east-2.amazonaws.com/octopus.product.assets.dev',
  S3_PATH: 'https://s3.amazonaws.com/',
  S3_BUCKET_NAME: 'octopus.product.assets.dev',
  S3_DST_FOLDER: 'original',
  CMS_URL: 'https://test.bemarscloud.com/wp-json/wp/v2/'
};
