import { S3, config } from 'aws-sdk';
import { environment } from 'src/environments/environment';

export class S3Factory {

    private static s3Conector = {};
    private static awsConfig;

    static loadAwsConfing() {
        this.awsConfig = {
            credentials: {
                user: environment.AWS_USER,
                accessKeyId: environment.AWS_ACCESS_KEY_ID,
                secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
                region: environment.AWS_REGION
            },
            s3 : {
                path: environment.S3_PATH,
                bucket: environment.S3_BUCKET_NAME,
                dstFolder: environment.S3_DST_FOLDER,
                url: environment.S3_BUCKET_CLOUD_FRONT,
                bucket_url: environment.S3_BUCKET
            }
        };
    }

    static getS3() {
        if (!this.awsConfig) {
            this.loadAwsConfing();
        }
        config.update({
            region: this.awsConfig.credentials.region,
            accessKeyId: this.awsConfig.credentials.accessKeyId,
            secretAccessKey: this.awsConfig.credentials.secretAccessKey
        });
        S3Factory.s3Conector = new S3();
        return S3Factory.s3Conector as S3;
    }

    static getDistFolder(): string {
        return this.awsConfig.s3.dstFolder;
    }

    static getBucket(): string {
        return this.awsConfig.s3.bucket;
    }

    static getBaseUrl(): string {
      return this.awsConfig.s3.url;
    }
}
