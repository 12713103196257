import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isEmpty } from 'lodash';
import * as _ from 'lodash';

import { ICompany, PaginateResult } from '../models/index';
import { UtilService } from './util.service';
import { environment } from '../../environments/environment';
import { API } from '../constants/api';
import { PaginatedResult } from '../models/paginated-result';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  private url;

  constructor(public http: HttpClient, private utilsService: UtilService) {
    this.url = `${environment.API_URL}${API.COMPANIES}`;
  }

  public createCompany(data: any): Observable<any> {
    return this.http.post(`${this.url}`, data);
  }

  public getAll(query?: any): Observable<PaginatedResult<ICompany>> {
    const queryParams: string = isEmpty(query) ? '' : '?' + this.utilsService.toQueryString(query);
    return this.http.get<PaginatedResult<ICompany>>(`${this.url}/paginate${queryParams}`);
  }

  public updateCompany(company: ICompany): Observable<any> {
    return this.http.put<ICompany>(`${this.url}/${company._id}`, company);
  }

  public getCompanies(query?: any): Observable<PaginateResult<ICompany>> {
    const queryParams: string = _.isEmpty(query) ? '' : '?' + this.utilsService.toQueryString(query);
    return this.http.get<PaginateResult<ICompany>>(`${this.url}${queryParams}`);
  }

  public getCompanyById(id): Observable<ICompany> {
    return this.http.get<ICompany>(this.url + '/' + id);
  }

  public existsCompany(data: any): Observable<any> {
    return this.http.post(this.url + '/exists', data);
  }

  public delete(company: ICompany): Observable<any> {
    return this.http.delete<ICompany>(`${this.url}/admin/${company._id}`);
  }

}
